
/*................................................
    BASIC STYLING FOR COMMON HTML ELEMENTS
.................................................*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

html {
    font-size: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

* {
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: unset;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.005);
    text-rendering: optimizeLegibility !important;
}

a,
input[type="button"],button {
    text-decoration: none;
    cursor: pointer;
}
a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none;
}
a:focus,a:hover{
    text-decoration: none;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
img,
input,
textarea,
fieldset {
    border: 0px;
    outline: none;
    resize: none;
}
input,
textarea {
    box-shadow: none;
    font-weight: 500;
    &:-webkit-autofill {
        background: none;
    }
}

li {
    list-style: none;
}
input::-ms-clear {
    display: none;
}

body {
    font-size: $font-nor;
    font-weight: $font-light;
    width: 100%;
    min-height: 100%;
    position: relative;
    overflow-x: hidden;
    color:$black-txt;
    font-family: 'Poppins', sans-serif;
}
button:disabled{
    opacity: 0.3;
}

.flex-btw{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.flex-c{
    display: flex;
    align-items: center;
}
.flex-c-m{
    display: flex;
    align-items: center;
    justify-content: center;
}
.grey-bg{
    background-color: $grey-bg;
}
.dark-grey-bg{
    background-color: $dark-grey;
}
.light-grey-bg{
    background-color: $light-grey;
}
.light2-grey{
    color: $light2-grey;
}
.subheading-grey{
    color:$subheading-grey
}
.txt-12{
    font-size: $font-small;
}
.txt-15{
    font-size: $font-semimed;
}
.txt-14{
    font-size: $font-med;
}
.flip-icon{
    transform: rotate(180deg);
}
.red-color{
    color:$red ;
}
.input-error{
    color: $red;
    margin-top: 8px;
    font-size: $font-med;
    display: block
}
.modal-content {
    background-color: $light-grey;
    min-width: fit-content;
}
.font-light{
    font-weight: $font-light;
}
.font-bold{
    font-weight: $font-bold!important;
}
.semi-bold{
    font-weight: $font-semibold;
}
.grey-lbtn{
    background-color: $btn-bg;
    padding: 9px 7px;
    border: none;
    font-size: 16px;
    border-radius: 3px;
    color: $dark2-grey; 
}
.btn-active-border{
    border:solid 1px $dark2-grey;
}
.pointer{
    cursor: pointer;
}

.form-group {
    margin-bottom: 1rem;
}

.form-control.py-4{
    padding-top:14px !important;
    padding-bottom: 14px!important;
}